<template>
  <v-dialog
      v-model="dialog"
      :disabled="processing"
      persistent
      max-width="362px"
  >
    <template #activator="{ on: onDialog }">
      <v-tooltip left>
        <template #activator="{ on: onTootip }">
          <v-icon
              :disabled="disabled"
              @click="dialog = true"
              v-on="{ ...onDialog, ...onTootip }"
          >
            {{ icon }}
          </v-icon>
        </template>
        {{ tooltip }}
      </v-tooltip>
    </template>
    <v-card :disabled="processing" :loading="processing">
      <v-card-text class="title pt-3">
        Esta ação não poderá ser desfeita. Deseja continuar?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Não
        </v-btn>
        <v-btn
            color="primary"
            depressed
            @click="actionConfirm"
        >
          Sim
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <custom-snackbar ref="snackbar" />
  </v-dialog>
</template>

<script>
import Snackbar from "@/core/components/Snackbar.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: 'MyActionConfirmation',

  components: {Snackbar},

  props: {
    action: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    processing: false,
  }),

  methods: {
    async actionConfirm() {
      try {
        this.processing = true

        if (this.action === 'delete') {
          await ApiService.delete(this.resource)
        } else if (this.action === 'update') {
          await ApiService.put(this.resource)
        } else {
          throw(`Ação [${this.action}] não permitida`)
        }

        this.$refs.snackbar.show(
            'Tudo certo!',
            'Sua solicitação foi realizada com sucesso.',
            'success',
        )

        this.$emit('success')
      } catch (e) {
        if (e.response) {
          if (e.response.data.message) {
            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                e.response.data.message,
                'danger',
            )
          } else {
            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                'Tente novamente mais tarde',
                'danger',
            )
          }
        } else {
          this.$refs.snackbar.show('Desculpe, algo deu errado!', e, 'danger')
        }
      }

      this.dialog = false

      this.processing = false
    },
  },
}
</script>