import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":!_vm.fillHeader ? 'transparent' : '',"flat":!_vm.fillHeader}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VSpacer),(_vm.showAddAction && !_vm.editable)?_c(VBtn,{attrs:{"color":"primary","grow":"","to":((_vm.$route.fullPath) + "/create"),"icon":""}},[_c(VIcon,[_vm._v("mdi-plus")])],1):_vm._e(),(_vm.showAddAction && _vm.editable)?_c(VBtn,{attrs:{"color":"primary","grow":"","icon":""},on:{"click":_vm.addItem}},[_c(VIcon,[_vm._v("mdi-plus")])],1):_vm._e()],1),_c(VCardText,{class:!_vm.fillHeader ? 'pa-0' : ''},[_c(VCard,{attrs:{"flat":_vm.fillHeader,"outlined":_vm.fillHeader||_vm.outlined}},[_c(VDataTable,{attrs:{"disable-sort":"","disable-filtering":"","headers":_vm.computedHeaders,"hide-default-footer":"","hide-default-header":"","items":_vm.items,"items-per-page":_vm.itemsPerPage,"loading":_vm.internalLoading || _vm.loading,"page":_vm.page},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header text-uppercase"},[_c('tr',_vm._l((headers),function(header,i){return _c('th',{key:header.value,staticClass:"primary white--text",class:i===0?'rounded-tl':i+1===headers.length?'rounded-tr':'',style:(_vm.getHeaderStyle(header)),attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)])]}},_vm._l((Object.keys(this.$scopedSlots)),function(slot){return {key:slot,fn:function(props){return [_vm._t(slot,null,null,props),(slot==='item.actions' && _vm.showEditAction)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":_vm.disableEditAction(props.item),"icon":"","small":""},on:{"click":function($event){return _vm.$router.push(((_vm.$route.fullPath) + "/" + (props.item.id) + "/edit"))}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-pencil-outline ")])],1)]}}],null,true)},[_vm._v(" Editar ")]):_vm._e(),(slot==='item.actions' && _vm.showDeleteAction)?_c('my-action-confirmation',{attrs:{"action":"delete","disabled":_vm.disableDeleteAction(props.item),"icon":"mdi-delete-outline","resource":(_vm.resource + "/" + (props.item.id) + "/delete"),"tooltip":"Excluir"},on:{"success":function($event){return _vm.removeItem(props.item)}}}):_vm._e()]}}})],null,true)})],1),(!_vm.disablePagination && _vm.items.length > 0)?_c(VPagination,{staticClass:"py-2",attrs:{"length":_vm.pageCount,"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1),_c('custom-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }