<template>
  <v-card :color="!fillHeader ? 'transparent' : ''" :flat="!fillHeader">
    <v-card-title>
      {{ title }}
      <v-spacer />
      <v-btn
          v-if="showAddAction && !editable"
          color="primary"
          grow
          :to="`${$route.fullPath}/create`"
          icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
          v-if="showAddAction && editable"
          color="primary"
          grow
          icon
          @click="addItem"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text :class="!fillHeader ? 'pa-0' : ''">
      <v-card :flat="fillHeader" :outlined="fillHeader||outlined">
        <v-data-table
            disable-sort
            disable-filtering
            :headers="computedHeaders"
            hide-default-footer
            hide-default-header
            :items="items"
            :items-per-page="itemsPerPage"
            :loading="internalLoading || loading"
            :page.sync="page"
        >
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header text-uppercase">
            <tr>
              <th
                  v-for="(header, i) in headers"
                  :key="header.value"
                  class="primary white--text"
                  :class="i===0?'rounded-tl':i+1===headers.length?'rounded-tr':''"
                  scope="col"
                  :style="getHeaderStyle(header)"
              >
                {{ header.text }}
              </th>
            </tr>
            </thead>
          </template>

          <template v-for="slot in Object.keys(this.$scopedSlots)" v-slot:[slot]="props" :editedItem="editedItem">
            <slot :name="slot" v-bind="props" />

            <v-tooltip v-if="slot==='item.actions' && showEditAction" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :disabled="disableEditAction(props.item)"
                    icon
                    small
                    @click="$router.push(`${$route.fullPath}/${props.item.id}/edit`)"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>
                    mdi-pencil-outline
                  </v-icon>
                </v-btn>
              </template>
              Editar
            </v-tooltip>

            <my-action-confirmation
                v-if="slot==='item.actions' && showDeleteAction"
                action="delete"
                :disabled="disableDeleteAction(props.item)"
                icon="mdi-delete-outline"
                :resource="`${resource}/${props.item.id}/delete`"
                tooltip="Excluir"
                @success="removeItem(props.item)"
            />
          </template>
        </v-data-table>
      </v-card>
      <v-pagination
          v-if="!disablePagination && items.length > 0"
          v-model="page"
          class="py-2"
          :length="pageCount"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
      ></v-pagination>
    </v-card-text>
    <custom-snackbar ref="snackbar" />
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import MyActionConfirmation from "@/view/components/MyActionConfirmation.vue";
import router from "@/router";
import _ from "lodash";

export default {
  name: 'MyListCard',
  components: {MyActionConfirmation},
  props: {
    title: {
      type: String,
      default: 'List Name',
    },
    actions: {
      type: Array,
      default: [],
    },
    defaultItem: {
      type: Object,
      default: () => ({}),
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    resource: {
      type: String,
      default: '',
    },
    showEditAction: {
      type: Boolean,
      default: false,
    },
    showDeleteAction: {
      type: Boolean,
      default: false,
    },
    fillHeader: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
    showAddAction: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    internalLoading: true,
    page: 1,
    pageCount: 0,
    editedItem: [],
    items: [],
  }),

  computed: {
    computedHeaders () {
      if (this.showDetail) {
        return [{
          value: 'show',
          align: 'center',
          width: 0,
        }].concat(this.headers);
      } else {
        return this.headers
      }
    },
  },
  watch: {
    page() {
      this.loadList()
    },
  },

  methods: {
    router() {
      return router
    },

    async loadList () {
      this.internalLoading = true
      this.items = []

      try {
        if (!this.disablePagination) {
          const params = {
            itemsPerPage: this.itemsPerPage,
            page: this.page,
            ...this.params,
          }

          const {data, last_page} = (await ApiService.get(this.resource, params)).data

          this.items = data
          this.pageCount = last_page
        } else {
          this.items = (await ApiService.get(this.resource, this.params)).data
        }

        if (this.editable) {
          this.items.forEach(item => {
            this.$set(item, 'editing', false)
          })
        }
      } catch (e) {
        if (e.response.status === 500) {
          this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              e.response.data.message,
              'danger',
          )
        } else {
          this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              'Tente novamente mais tarde',
              'danger',
          )
        }
      }

      this.internalLoading = false
    },

    addItem () {
      this.items.push(Object.assign({}, this.defaultItem))
    },

    editItem (item) {
      this.editedItem[this.items.indexOf(item)] = _.clone(item)
      item.editing = true
    },

    cancelEditItem (item) {
      if (item.id === 0) {
        this.items.splice(this.items.indexOf(item), 1)
        return
      }

      this.$set(this.items, this.items.indexOf(item), _.clone(this.editedItem[this.items.indexOf(item)]))
      delete this.editedItem[this.items.indexOf(item)]
    },

    removeItem (item) {
      this.items.splice(this.items.indexOf(item), 1)
    },

    disableDeleteAction(item) {
      if (typeof this.$parent.disableDeleteAction === "function") {
        return this.$parent.disableDeleteAction(item)
      }

      return false
    },

    disableEditAction(item) {
      if (typeof this.$parent.disableEditAction === "function") {
        return this.$parent.disableEditAction(item)
      }

      return false
    },

    getHeaderStyle (header) {
      let style = ''

      if (header.align) {
        style += `text-align: ${header.align};`
      }

      if (header.width !== undefined) {
        style += `width: ${header.width}px;`
      }

      return style
    },

    async save(item) {
      this.formProcessing = true

      const index = this.items.indexOf(item)

      this.$emit('validate', [])

      try {
        const params = { parent_id: parseInt(this.$route.params.id), ...item }
        if (item.id === 0) {
          item = (await ApiService.post(this.resource, params)).data
        } else {
          item = (await ApiService.put(`${this.resource}/${item.id}`, params)).data
        }

        item.editing = false

        this.$set(this.items, index, item)

        this.$refs.snackbar.show(
            'Tudo certo!',
            'Operação realizada com sucesso',
            'success',
            '2000'
        )
      } catch (e) {
        if (e.response) {
          if (e.response.status === 422) {
            item.errors = e.response.data.errors

            this.$set(this.items, index, item)

            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                'Verifique os campos em vermelho e tente novamente',
                'danger',
                3000
            )
          } else if (e.response.data.message) {
            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                e.response.data.message,
                'danger',
            )
          } else {
            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                'Tente novamente mais tarde',
                'danger',
            )
          }
        } else {
          this.$refs.snackbar.show('Desculpe, algo deu errado!', e, 'danger')
        }
      }

      this.formProcessing = false
    },
  },

  mounted () {
    window.scrollTo(0, 0)
    this.loadList()
  },
}
</script>

<style scoped>
>>> tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0);
  .theme--light.v-data-table {
    background-color: transparent;
    tr:hover {
      background-color: rgba(0, 0, 0, .06);
    }
  }
}
</style>