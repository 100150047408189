import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"disabled":_vm.processing,"persistent":"","max-width":"362px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onDialog = ref.on;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTootip = ref.on;
return [_c(VIcon,_vm._g({attrs:{"disabled":_vm.disabled},on:{"click":function($event){_vm.dialog = true}}},Object.assign({}, onDialog, onTootip)),[_vm._v(" "+_vm._s(_vm.icon)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.tooltip)+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"disabled":_vm.processing,"loading":_vm.processing}},[_c(VCardText,{staticClass:"title pt-3"},[_vm._v(" Esta ação não poderá ser desfeita. Deseja continuar? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Não ")]),_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":_vm.actionConfirm}},[_vm._v(" Sim ")]),_c(VSpacer)],1)],1),_c('custom-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }